<template>
	<div class="o-row">
		<div class="o-container">
			<div class="c-dashboard">
				<h3>Oefenexamens</h3>
				<p>
					Deze oefenexamens bestaan uit 5 korte videofragmenten van het dagdagelijkse verkeer.<br />
					Na elke video volgt er een vraag met 4 antwoorden, waarvan er minstens 1 tot 3 correct zijn.<br />
					Je moet minstens een 6/10 halen om te slagen.
				</p>

				<div class="c-dashboard__exams">
					<ExamCard v-for="exam in exams" :key="exam.id" :exam="exam" />
				</div>
			</div>
		</div>
	</div>
</template>

<script src="./Dashboard.controller.js"></script>
<style lang="scss" src="./Dashboard.styles.scss"></style>
