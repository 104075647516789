import { ExamCard } from "@/components";

export default {
	name: "Dashboard",
	components: {
		ExamCard,
	},
	data: () => ({
		example: true,
		exams: [
			{
				id: 1,
				nameDutch: "Oefenexamen 01",
				nameFrench: "Examen pratique 01",
				thumbnailFileName: "thumbnail01.png",
				completedDate: "2022-02-12T22:29:07.711Z",
				isLocked: false,
				score: 4,
			},
			{
				id: 2,
				nameDutch: "Oefenexamen 02",
				nameFrench: "Examen pratique 02",
				thumbnailFileName: "thumbnail02.png",
				isLocked: true,
				score: 0,
			},
			{
				id: 3,
				nameDutch: "Oefenexamen 03",
				nameFrench: "Examen pratique 03",
				thumbnailFileName: "thumbnail03.png",
				isLocked: true,
				score: 0,
			},
			{
				id: 4,
				nameDutch: "Oefenexamen 04",
				nameFrench: "Examen pratique 04",
				thumbnailFileName: "thumbnail04.png",
				isLocked: true,
				score: 0,
			},
			{
				id: 5,
				nameDutch: "Oefenexamen 05",
				nameFrench: "Examen pratique 05",
				thumbnailFileName: "thumbnail05.png",
				isLocked: true,
				score: 0,
			},
			{
				id: 6,
				nameDutch: "Oefenexamen 06",
				nameFrench: "Examen pratique 06",
				thumbnailFileName: "thumbnail06.png",
				isLocked: true,
				score: 0,
			},
			{
				id: 7,
				nameDutch: "Oefenexamen 07",
				nameFrench: "Examen pratique 07",
				thumbnailFileName: "thumbnail07.png",
				isLocked: true,
				score: 0,
			},
		],
	}),
	methods: {},
};
